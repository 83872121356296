/*-----------------------------------
[Table of Contents]
1. Base
2. Logo
3. Home and nav
4. Content
5. Client
6. Icon-text
7. Members
8. Contact
9. Work
10. Post
11. Form
12. Case study

--------------*/

/* base */

a {
  color: #ff5242;
}

a:hover {
  color: #000;
}

a.load-more {
  width: 140px;
  height: 140px;
  display: block;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
  border: 1px solid #ccc;
  transform: translateX(-50%) scale(0.9);
  transition: all .2s cubic-bezier(.645,.045,.355,1);
  text-align: center;
  line-height: 140px;
  position: relative;
  left: 50%;
  background: #333;
  color: #fff;
}

a.load-more:hover {
  transform: translateX(-50%) scale(1);
}

.spacing {
  height: 20px;
  clear: both;
   }

.two.spacing {
  height: 40px;
  clear: both;
}

.three.spacing {
  height: 60px;
  clear: both;
}

.four.spacing {
  height: 80px;
  clear: both;
}

h1, h2, h3, h4, h5, h6 {
  font-family: montserrat;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 1.2em;
  margin-bottom: 40px;
  text-transform: uppercase;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h2.white {
  color: #fff;
}

.appraiser {
  font-family: montserrat;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 13px;
}

hr {
  max-width: 100%;
  border-bottom: 1px solid #00e1b6;
}

.button {
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-family: montserrat, sans-serif;
  letter-spacing: 1px;
  line-height: 1.4rem;
  padding: 10px 30px;
}

.button.boxed.white {
  border: 1px solid #fff;
  background: none;
  color: #fff;
}

.button.boxed.white:hover {
  color: #333;
}

.button.boxed.black {
  border: 1px solid #333;
  background: none;
  color: #333;
}

.button.boxed.black:hover {
  color: #fff;
  background: #333;
}

.full {
  clear: both;
  padding: 160px 10%;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  overflow: auto;
}

.full-screen {
  height: 100%;
}

.full.black {
  background: #000;
}

.full.no-bottom {
  padding-bottom: 0;
}

.full.grey {
  background: #eee;
}

.full.light-grey {
  background: #f5f5f5;
}

.full.colored-bg {
  background: #eee;
  transition: background 0.2s ease-in;
}

.full.colored-bg.red.appear {
  background: #fc8479;
}

.full.colored-bg.purple.appear {
  background: #6e77c6;
}

.full.colored-bg.orange.appear {
  background: #f2be8c;
}

.full.footer-bg {
  background-color: #ffffff;
  background-image: url(../../images/@stock/footer-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.full.full-height {
  height: 100vh;
}

.centered-text {
  text-align: center;
}

/*logo*/
.logo {
  position: absolute;
  left: 35px;
  top: 30px;
  z-index: 500;
}

.logo a {
  color: #000;
  font-size: 24px;
  font-family: montserrat;
  font-weight: bold;
}

/* home and nav */
.box {
  width: 50%;
  height: 50%;
  display: block;
  position: absolute;
  overflow: hidden;
}

#about {
  left: 0;
  top: 0;
  .header {
    background: #fff;
  }
}

#works {
  right: 0;
  top: 0;
  .header {
    background: #f5f5f5;
  }
}

#blog {
  left: 0;
  bottom: 0;
  .header {
    background: #f5f5f5;
  }
}

#contact {
  right: 0;
  bottom: 0;
  .header {
    background: #e9e9e9;
  }
}

.header  {
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.header nav {
  width: 100%;
  height: 100%;
}

.header nav h2 {
  width: 100%;
  height: 100%;
  margin: 0;
}

.header a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
}

.header span {
  position: absolute;
  z-index: 400;
  display: inline-block;
  bottom: 30%;
  font-size: 20px;
  color: #000;
  text-transform: uppercase;
  font-weight:700;
  font-family: montserrat;
  line-height: 1em;
  transition-property: left;
  transition-duration: .3s;
}

#about .header span {
  left: 60%;
}

#works .header span {
  left: 58%;
}
#blog .header span {
  left: 57%;
}

#contact .header span {
  left: 56%;
}

.header .title span.c {
  letter-spacing: -30px;
}

.header .title span.w {
  letter-spacing: -140px;
}

.header .title span.b {
  letter-spacing: -45px;
}

.color-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.1s;
}

.color-overlay.orange {
   background-color: rgba(245, 104, 35, 0.9);
}

.color-overlay.green {
   background-color: rgba(38, 165, 86, 0.9);
}

.dark-overlay {
  width: 100%;
  height: 100%;
  display: block;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.1s;
}

.header:hover .title {
  opacity: 0.2;
  left: -20%;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.header:hover .dark-overlay, .header:hover .color-overlay {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
}

#back {
  position: fixed;
  color: #000;
  z-index: 999;
  top: 10px;
  right: 25px;
  font-size: 36px;
  line-height: 36px;
  padding: 20px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#back:hover {
  transform: rotate(360deg);
  transition-property: transform;
  transition-duration: 0.6s;
  transition-timing-function: ease;
}


svg path, svg polygon {
  transform: scale(0.9);
  transform-origin: center;
  transition-property: transform;
  transition-duration: .3s;
}

body.hover-about svg #svg-about path, body.hover-about svg #svg-about polygon,
body.hover-works svg #svg-works path, body.hover-works svg #svg-works polygon,
body.hover-blog svg #svg-blog path, body.hover-blog svg #svg-blog polygon,
body.hover-contact svg #svg-contact path, body.hover-contact svg #svg-contact polygon {
  transform: scale(1.2);
  transform-origin: center;
  transition-property: transform;
  transition-duration: .3s;
}

.box:hover .header span {
  bottom: 30%;
  transition-property: left;
  transition-duration: .3s;
}

#about:hover .header span {
  left: 70%;
}

#works:hover .header span {
  left: 68%;
}

#blog:hover .header span {
  left: 67%;
}

#contact:hover .header span {
  left: 67%;
}

.header:hover .nav-video {
  filter: none;
}

svg #svg-about .path {
  transform-origin: center;
  transition-property: transform;
  transition-duration: .3s;
}

.header svg {
  position: static;
  display: block;
  transform: none;
  z-index: 300;
  opacity: 1;
  transition: none;

  transform-origin: center;

  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  // scale 1.01 to hide the vertical line on the right
  // the svg be a bit big than the video
  transform: translate3d(-50%, -50%, 0) scale(1.01);
  margin: 0;
  z-index: 2;
}

svg#svg-graphic-about {

  & > rect {
    -webkit-mask: url(#about-mask);
    mask: url(#about-mask);
    fill: #fff;
  }

  .path {
    transform: scale(1);
    transform-origin: center;
    fill: #000;
  }

}

svg#svg-graphic-works {

  & > rect {
    -webkit-mask: url(#works-mask);
    mask: url(#works-mask);
    fill: #f5f5f5;
  }

  .path {
    transform: scale(1);
    transform-origin: center;
    fill: #000;
  }

}

svg#svg-graphic-blog {

  & > rect {
    -webkit-mask: url(#blog-mask);
    mask: url(#blog-mask);
    fill: #f5f5f5;
  }

  .path {
    transform: scale(1);
    transform-origin: center;
    fill: #000;
  }

}

svg#svg-graphic-contact {

  & > rect {
    -webkit-mask: url(#contact-mask);
    mask: url(#contact-mask);
    fill: #e9e9e9;
  }

  .path {
    transform: scale(1);
    transform-origin: center;
    fill: #000;
  }

}

body > svg {
  display: none;
}



/* content */

.invisible {
  visibility: hidden;
}

.box {
  transition-duration: .3s;
  transition-timing-function: ease-out;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.box.inactive-w-0 {
  width: 0;
}

.box.inactive-h-0 {
  height: 0;
}

.box.inactive-h-w-0 {
  width: 0;
  height: 0;
}

.nav-video {
  width: 600px;
  height: auto;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin: 0;
}

html.no-touchevents .nav-video {
  filter: contrast(110%) brightness(103%);
}

.box.active {
  transition-timing-function: ease-in;
  width: 100%;
  height: 100%;
  padding: 80px 20px 20px;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  .header-title {
    visibility: visible;
    position: absolute;
    top: 40%;
    left: 10%;
    z-index: 2;
    padding-right: 10%;
    color: #fff;
    h2 {
      font-size: 60px;
      font-family: montserrat;
      margin-bottom: 40px;
    }
    p {
      font-size: 24px;
      margin-bottom: 40px;
      line-height: 1.3em;
    }
  }
  .content {
    visibility: visible;
  }

  .dark-overlay {
    display: none;
  }

  .header a {
    cursor: default;
    overflow: hidden;
  }

  .header h2 {
    overflow: hidden;
  }

  .header {
    position: relative;
    width: 100%;
    overflow: visible;
  }

  .header span {
    display: none;
  }

  .nav-video {
    transition: none;
  }
}

#about.box.active svg, #works.box.active svg, #blog.box.active svg, #contact.box.active svg {
  display: none;
}


#about.box.active .nav-video, #works.box.active .nav-video, #blog.box.active .nav-video, #contact.box.active .nav-video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin: 0;
  filter: none;
}

.colored-title {
  color: #ff5242;
}

.big-text {
  font-size: 24px;
  line-height: 1.9em;
}


@keyframes BackgroundAnimated-l-r{
  from {
    background-position:0 0;
  }
  to {
    background-position:100px 0;
  }
}

@-webkit-keyframes BackgroundAnimated-l-r {
  from {
    background-position:0 0;
  }
  to {
    background-position:100px 0;
  }
}

@-ms-keyframes BackgroundAnimated-l-r {
  from {
    background-position:0 0;
  }
  to {
    background-position:100px 0;
  }
}

@-moz-keyframes BackgroundAnimated-l-r {
  from {
    background-position:0 0;
  }
  to {
    background-position:100px 0;
  }
}

@keyframes BackgroundAnimated-t-b{
  from {
    background-position:0 0;
  }
  to {
    background-position:0 100px;
  }
}

@-webkit-keyframes BackgroundAnimated-t-b {
  from {
    background-position:0 0;
  }
  to {
    background-position:0 100px;
  }
}

@-ms-keyframes BackgroundAnimated-t-b {
  from {
    background-position:0 0;
  }
  to {
    background-position:0 100px;
  }
}

@-moz-keyframes BackgroundAnimated-t-b {
  from {
    background-position:0 0;
  }
  to {
    background-position:0 100px;
  }
}

@keyframes BackgroundAnimated-r-l{
  from {
    background-position:100px 0;
  }
  to {
    background-position:0 0;
  }
}

@-webkit-keyframes BackgroundAnimated-r-l {
  from {
    background-position:100px 0;
  }
  to {
    background-position:0 0;
  }
}

@-ms-keyframes BackgroundAnimated-r-l {
  from {
    background-position:100px 0;
  }
  to {
    background-position:0 0;
  }
}

@-moz-keyframes BackgroundAnimated-r-l {
  from {
    background-position:100px 0;
  }
  to {
    background-position:0 0;
  }
}

@keyframes BackgroundAnimated-b-t{
  from {
    background-position:0 100px;
  }
  to {
    background-position:0 0;
  }
}

@-webkit-keyframes BackgroundAnimated-b-t {
  from {
    background-position:0 100px;
  }
  to {
    background-position:0 0;
  }
}

@-ms-keyframes BackgroundAnimated-b-t {
  from {
    background-position:0 100px;
  }
  to {
    background-position:0 0;
  }
}

@-moz-keyframes BackgroundAnimated-b-t {
  from {
    background-position:0 100px;
  }
  to {
    background-position:0 0;
  }
}

.action-hint {
  font-family: montserrat;
  letter-spacing: 0px;
  font-size: 13px;
}

.scroll-down {

  i {
    font-size: 16px;
    color: #000;
    display: inline-block;
    line-height: 10px;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scrolldown;
    animation-name: scrolldown;
  }
}

@keyframes
scrolldown{
  from{
    transform:translateY(-5px);
  }
  to{
    transform:translateY(5px);
  }
}

@-webkit-keyframes
scrolldown{
  from{
    transform:translateY(-5px);
  }
  to{
    transform:translateY(5px);
  }
}


/* client */
.client {
  border: 1px solid #fff;
  text-align: center;
  padding: 30px 0;
  width: auto;
  height: auto;
  position: relative;
  overflow: hidden;
}

.clients .columns {
  padding: 0; }

.clients-with-bg img {
  background: #ccc;
  padding: 10px 0;
  -webkit-transition: all, 0.3s, ease-in-out;
  -moz-transition: all, 0.3s, ease-in-out;
  transition: all, 0.3s, ease-in-out;
}
.clients-with-bg img:hover {
  background: #333;
}

.client img {
  position: relative;
  transform: scale(1);
  transition: transform .4s cubic-bezier(.645,.045,.355,1);

}

.client:hover img {
  transform: scale(0.95);
}

.client:hover::before {
  transform: scale(1.5);
}

.client::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  -ms-transform: scale(0);
  transform: scale(0);
  background: #ff5242;
  transition: transform .4s cubic-bezier(.645,.045,.355,1);
}

.client {
  background-color: #000000;
  background-image: url(../../images/@stock/client-bg.jpg);
  background-position: left top;
  background-repeat: no-repeat;
}

.circle-1 {
  position: absolute;
  right: 15%;
  top: 400px;
  width: 100px;
  height: 100px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  opacity: 0;
  transform: translateY(0);
  transition: all .4s cubic-bezier(.645,.045,.355,1);
  background-color: #fff;
  background-image: url(../../images/@stock/circle-1.png);
  background-position: center center;
  background-repeat: no-repeat;
}


.circle-2 {
  position: absolute;
  right: 30%;
  top: 100px;
  width: 40px;
  height: 40px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: #ff5242;
  opacity: 0;
  transform: translateY(0);
  transition: all .4s cubic-bezier(.645,.045,.355,1);
}

.circle-3 {
  position: absolute;
  left: 5%;
  bottom: 60px;
  width: 40px;
  height: 40px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  opacity: 0;
  transform: translateY(0);
  transition: all .4s cubic-bezier(.645,.045,.355,1);
  background-color: #ffffff;
  background-image: url(../../images/@stock/circle-3.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.circle-4 {
  position: absolute;
  left: 5%;
  top: 150px;
  width: 40px;
  height: 40px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  opacity: 0;
  transform: translateY(0);
  transition: all .4s cubic-bezier(.645,.045,.355,1);
  background-color: #ffffff;
  background-image: url(../../images/@stock/circle-3.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.triangle-1 {
  position: absolute;
  right: 25%;
  bottom: 200px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid red;
  opacity: 0;
  transform: translateY(0);
  transition: all .4s cubic-bezier(.645,.045,.355,1);
}

.triangle-2 {
  position: absolute;
  right: 25%;
  bottom: 50px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 40px solid red;
  opacity: 0;
  transform: translateY(0 ) rotate(-90deg);
  transition: all .4s cubic-bezier(.645,.045,.355,1);
}

.circle-1.appear, .circle-2.appear, .circle-3.appear, .circle-4.appear, .triangle-1.appear, .triangle-2.appear {
  opacity: 1;
  transform: translateY(-50px);
}

/* icon -text */

.icon-1 {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  background-image: url(../../images/@stock/service-1.png);
}


.icon-2 {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  background-image: url(../../images/@stock/service-2.png);
  background-repeat: repeat;
}

.icon-3 {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  background-image: url(../../images/@stock/service-3.png);
  background-repeat: repeat;
}

.icon-text h3 {
  margin-top: -50px;
  margin-left: 80px;
}

.icon-text ul {
  margin-left: 60px;
  margin-top: 50px;
  list-style: none;
  line-height: 1.9em;
  font-size: 18px;
}

.icon-text ul li::before {
  content: "";
  width: 10px;
  height: 1px;
  margin-right: 10px;
  display: inline-block;
  background: #ff5242;
}


.icon-text:hover .icon-2 {
  -webkit-animation:BackgroundAnimated-l-r 2s linear infinite;
  -moz-animation:BackgroundAnimated-l-r 2s linear infinite;
  -ms-animation:BackgroundAnimated-l-r 2s linear infinite;
  -o-animation:BackgroundAnimated-l-r 2s linear infinite;
  animation:BackgroundAnimated-l-r 2s linear infinite;
}

.icon-text:hover .icon-1 {
  -webkit-animation:BackgroundAnimated-t-b 2s linear infinite;
  -moz-animation:BackgroundAnimated-t-b 2s linear infinite;
  -ms-animation:BackgroundAnimated-t-b 2s linear infinite;
  -o-animation:BackgroundAnimated-t-b 2s linear infinite;
  animation:BackgroundAnimated-t-b 2s linear infinite;
}

.icon-text:hover .icon-3 {
  -webkit-animation:BackgroundAnimated-r-l 2s linear infinite;
  -moz-animation:BackgroundAnimated-r-l 2s linear infinite;
  -ms-animation:BackgroundAnimated-r-l 2s linear infinite;
  -o-animation:BackgroundAnimated-r-l 2s linear infinite;
  animation:BackgroundAnimated-r-l 2s linear infinite;
}



/* members */
.members .columns {
  padding: 0;
}

.modBoxedTextSlider {
  width: 100%;
  background: #000;
}

.modBoxedTextSlider .slidebox {
  background: #e9e9e9;
  padding-top: 60px;
  color: #fff;
  position: relative;
  ransition: background 0.1s ease-in;
}
.modBoxedTextSlider .slidebox h4, .modBoxedTextSlider .slidebox p {

}

.big-letter {
  color: #ff5242;
  opacity: 1;
  font-size: 280px;
  font-weight: 700;
  font-family: montserrat;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
   transition: opacity 0.2s ease-in;
}

.member-dec {
  background: #000;
  padding: 60px 0 60px 80px;
  transition: background 0.1s ease-in;
}

.member-dec h3 {
  margin-bottom: 10px;
  text-transform: none;
}

.member-dec p {
  color: #ccc;
  letter-spacing: 1px;
}

.members .slidebox:hover .big-letter {
  opacity: 0;
}

ul.socials {
  list-style: none;
  margin: 30px 0 0;
}
ul.socials li {
  display: inline-block;
}

ul.socials li a {
  color: #ff5242;
  display: inline-block;
  padding: 5px;
}

ul.socials li a:hover {
  color: #fff;
}



/* contact */

.contact-email em {
  display: block;
  font-size: 18px;
  color: #666;
}

.contact-email a {
  font-size: 18px;
  font-family: montserrat;
  font-weight: bold;
  color: #000;
  display: block;
  padding: 5px 0;
}

.contact-email a:hover {
  color: #ff5242;
}

.contact-details i {
  font-size: 36px;
  color: #ff5242;
}

.contact-details ul.socials {
   margin: 0;
}

.contact-details ul.socials i {
  font-size: 22px;
}


.contact-details ul.socials a {
  display: inline-block;
  padding: 8px 6px;
}

.contact-details ul.socials a i {
  color: #000;
}

.contact-details ul.socials a:hover i {
  color: #ff5242;
}

.contact-details p {
  margin-bottom: 5px;
}

.contact-details p a {
  color: #000;
}

.contact-details p a:hover {
  color: #ff5242;
}

.contact-details a.google-map-button {
  color: #000;
  font-family: montserrat, sans-serif;
  font-size: 11px;
  padding: 5px 10px 9px 5px;
}

.contact-details a.google-map-button i {
  color: #000;
  font-size: 20px;
  position: relative;
  top: 5px;
  font-weight: bold;
}

.contact-details a.google-map-button:hover i {
  color: #fff;
}

.contact-details {
  min-height: 300px;
}

.contact-button {
  padding: 10px 80px;
  color: #fff !important;
}

.contact-button:hover {
  color: #ccc !important;
}


/* work */

.works {
  overflow: hidden;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.works .columns {
  padding: 0;
}

.item .details h2 {
  font-family: lora;
  font-size: 18px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: #000;
}

.item .details h3 {
  margin-bottom: 10px;
  color: #000;
}

.item {
  width: 100%;
  height: 35vw;
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.2s ease;
}

.item::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #e0e0e0;
  position: absolute;
}

.item a {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 40px;
}

.circle-wrap {
  border-radius: 0;
  -webkit-mask-image: url(../../images/circle.svg);
  -webkit-mask-position: center center;
  -webkit-mask-size: 70% 70%;
  -webkit-mask-repeat: no-repeat;
  width: 100%;
  height: 24.3vw;
  overflow: hidden;
  transition: all 0.2s ease;
}

.circle-wrap .media {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.circle-wrap .work-1 {
  background-image: url(../../images/@stock/work-1.gif);
}

.circle-wrap .work-2 {
  background-image: url(../../images/@stock/work-2.jpg);
}

.circle-wrap .work-3 {
  background-image: url(../../images/@stock/work-3.jpg);
}

.circle-wrap .work-4 {
  background-image: url(../../images/@stock/work-4.jpg);
}

.circle-wrap .work-5 {
  background-image: url(../../images/@stock/work-5.jpg);
}

.circle-wrap .work-6 {
  background-image: url(../../images/@stock/work-7.jpg);
}

.circle-wrap .work-7 {
  background-image: url(../../images/@stock/work-11.gif);
}

.circle-wrap .work-8 {
  background-image: url(../../images/@stock/work-8.jpg);
}

.circle-wrap .work-8 {
  background-image: url(../../images/@stock/work-8.gif);
}

.circle-wrap .work-9 {
  background-image: url(../../images/@stock/work-9.jpg);
}

.circle-wrap .work-10 {
  background-image: url(../../images/@stock/work-10.jpg);
}

.circle-wrap .work-11 {
  background-image: url(../../images/@stock/work-11.jpg);
}

.circle-wrap .work-12 {
  background-image: url(../../images/@stock/work-12.jpg);
}

.circle-wrap .work-13 {
  background-image: url(../../images/@stock/work-13.jpg);
}

.circle-wrap .work-14 {
  background-image: url(../../images/@stock/work-12.gif);
}

.circle-wrap .work-15 {
  background-image: url(../../images/@stock/work-15.gif);
}
.circle-wrap img {
  position: absolute;
  top: 40px;
  left: 0;
}

.item .client-name {
  position: absolute;
  left: 50px;
  top: 82%;
  width: 30%
}

.item .project{
  position: absolute;
  right: 20px;
  top: 82%;
  width: 30%;
}

.item a h2 {
  font-size: 9px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
}

.item a h2::before {
  content: "";
  width: 10px;
  height: 1px;
  display: block;
  background: #000;
  position: absolute;
  left: -15px;
  top: 5px;
}

.item a h3 {
  font-size: 14px;
  color: #000;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-family: lora;
  text-transform: capitalize;
}

.circle-wrap .details {
  position: absolute;
  z-index: 10;
  opacity: 0;
  width: 100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  transition: opacity 0.1s ease;
}

.circle-wrap::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f9dda7;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='105' viewBox='0 0 80 105'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='death-star' fill='%23a8a963' fill-opacity='0.49'%3E%3Cpath d='M20 10a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm15 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zM20 75a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zm30-65a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm0 65a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zM35 10a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zM5 45a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zm60 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.item:hover .circle-wrap {
  -webkit-mask-size: 75% 75%;
}

.item:hover {
  background: #ff5242;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff5242' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

}

.item:hover .circle-wrap .details {
  opacity: 1;
}


/* Post */

.posts {
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  overflow: auto;
}

.posts .columns {
  padding: 0;
}

.post {
  background: #fff;
  padding: 80px 60px;
  position: relative;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-top: none;
  height:350px;
}

.posts-right .post {
  border-left: none;
}

.posts-right .post:last-child {
  border-left: 1px solid #e0e0e0;
}

.post h2 {
  font-size: 28px;
  margin-bottom: 5px;
}

.post .info-author {
  color: #666;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: italic;
}

.post .info-others span {
  font-family: montserrat;
  font-size: 13px;
  margin-right: 20px;
}

.post .info-author a {
  color: #666;
}

.post .info-others a {
  color: #333;
}

.post-desc {
  position: relative;
  z-index: 10;
}

.post-image-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

.post-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(1.1);
  transition: all 0.5s ease;
}

.post-image-1 {
  background: url(../../images/@stock/work-9.jpg) center center no-repeat;
  background-size: cover;
}

.post-image-2 {
  background: url(../../images/@stock/work-10.jpg) center center no-repeat;
  background-size: cover;
}

.post-image-3 {
  background: url(../../images/@stock/work-1.gif) center center no-repeat;
  background-size: cover;
}

.post-image-4 {
  background: url(../../images/@stock/work-4.jpg) center center no-repeat;
  background-size: cover;
}

.post-image-5 {
  background: url(../../images/@stock/work-1.jpg) center center no-repeat;
  background-size: cover;
}

.post-image-6 {
  background: url(../../images/@stock/work-6.jpg) center center no-repeat;
  background-size: cover;
}

.post-image-7 {
  background: url(../../images/@stock/work-14.gif) center center no-repeat;
  background-size: cover;
}

.post-image-8 {
  background: url(../../images/@stock/work-3.jpg) center center no-repeat;
  background-size: cover;
}


.post:hover, .post.featured  {
  color: #fff;
  background: #ff5242;
}

.post:hover .info-author, .post.featured .info-author {
  color: #fff;
}

.post:hover a, .post.featured a {
  color: #fff;
}

.post:hover a.button, .post.featured a.button {
  color: #fff;
  border: 1px solid #fff;
}

.post:hover a.button:hover, .post.featured a.button:hover {
  color: #000;
  background: #fff;
}

.post:hover .post-image, .post.featured .post-image {
  opacity: 0.9;
  filter: grayscale(1);
  mix-blend-mode: multiply;
  transform: scale(1);
}

body.single .post {
  color: #fff;
  background: #ff5242;
  min-height: 500px;
  padding-top: 150px;
}

body.single .post .post-image  {
  opacity: 0.9;
  filter: grayscale(1);
  mix-blend-mode: multiply;
  transform: scale(1);
}

body.single .post a, body.single .post .info-author {
  color: #fff;
}

body.single .post h2 {
  font-size: 36px;
}

.post-content p:first-of-type {
  font-size: 24px;
}

body.single .post-content p, body.single .post-content blockquote, body.single .post-content h2, body.single .post-content h3, body.single .post-content h4, body.single .post-content h5, body.single .post-content ul, body.single .post-content ol {
  margin-bottom: 36px;
}

.tags a {
  padding-right: 3px;
}

.comments-wrapper ul.comments {
  margin-left: 0;
}

.comments-wrapper ul.comments li {
  border-bottom: 1px solid #eee;
  padding: 20px 0 0;
  list-style: none;
}

.comments-wrapper ul.comments .meta .avatar {
  float: left;
  padding: 5px 10px 0 0;
}

.comments-wrapper ul.comments .meta .name {
  display: block;
}

.comments-wrapper ul.comments .meta .datetime {
  font-size: 0.875rem;
  color: #999;
}

.comments-wrapper ul.comments ul.children li {
  border-bottom: none;
  border-top: 1px solid #eee;
}


nav.wrapper {
  padding: 100px 10%;
  background: #f5f5f5;
  overflow: hidden;
}

nav.wrapper a span.sub-title {
  display: block;
  color: #000;
}


nav.wrapper a {
  white-space: nowrap;
}

nav.wrapper a span.title {
  font-size: 24px;
  font-weight: bold;
  font-family: montserrat;
  text-transform: uppercase;
  color: #000;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

nav.wrapper a.previous {
  float: left;
  display: block;
  width: 100%;
}

nav.wrapper a.next {
  float: right;
  text-align: right;
  display: block;
  width: 100%;
}

nav.wrapper a.previous i {
  position: relative;
  left: -20px;
  float: left;
  display: block;
  top: 10px;
  font-size: 36px;
  line-height: 36px;
  color: #000;
}

nav.wrapper a.next i {
  position: relative;
  right: -20px;
  float: right;
  display: block;
  top: 10px;
  font-size: 36px;
  line-height: 36px;
  color: #000;
}

nav.wrapper a:hover i, nav.wrapper a:hover span {
  color: #ff5242;
}

.posts.archive .post {
  padding: 40px 40px 20px;
}

.posts.archive .post-image {
  display: none;
}

.posts.archive .post:hover {
  background: none;
}

.posts.archive .post:hover {
  color: #ff5242;
  background: none;
}

.posts.archive .post:hover .info-author {
  color: #ff5242;
}

.posts.archive .post:hover a {
  color: #ff5242;
}

.posts.tag-archive .post {
  padding: 40px 40px 20px;
}

.posts.tag-archive .post-image {
  display: none;
}

.posts.tag-archive .post:hover {
  background: none;
}

.posts.tag-archive .post:hover {
  color: #ff5242;
  background: none;
}

.posts.tag-archive .post:hover .info-author {
  color: #ff5242;
}

.posts.tag-archive .post:hover a {
  color: #ff5242;
}

.posts.category-archive .post {
  padding: 40px 40px 20px;
}

.posts.category-archive .post-image {
  display: none;
}

.posts.category-archive .post:hover {
  background: none;
}

.posts.category-archive .post:hover {
  color: #ff5242;
  background: none;
}

.posts.category-archive .post:hover .info-author {
  color: #ff5242;
}

.posts.category-archive .post:hover a {
  color: #ff5242;
}



/* form */
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], textarea {
  padding: 15px;
  height: 3rem;
  margin-bottom: 1.875rem;
}

form input[type="text"], form input[type="password"], form input[type="date"], form input[type="datetime"], form input[type="datetime-local"], form input[type="month"], form input[type="week"], form input[type="email"], form input[type="number"], form input[type="search"], form input[type="tel"], form input[type="time"], form input[type="url"], form textarea {
  background: none;
  border: 1px solid rgba(#fff, 0.7);
  color: #fff;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="color"]:focus, textarea:focus {
  background: none;
  border: 1px solid #fff;
}

form.dark input[type="text"], form.dark input[type="password"], form.dark input[type="date"], form.dark input[type="datetime"], form.dark input[type="datetime-local"], form.dark input[type="month"], form.dark input[type="week"], form.dark input[type="email"], form.dark input[type="number"], form.dark input[type="search"], form.dark input[type="tel"], form.dark input[type="time"], form.dark input[type="url"], form.dark textarea {
  background: none;
  border: 1px solid #ccc;
  color: #333;
}

form.dark input[type="text"]:focus, form.dark input[type="password"]:focus, form.dark input[type="date"]:focus, form.dark input[type="datetime"]:focus, form.dark input[type="datetime-local"]:focus, form.dark input[type="month"]:focus, form.dark input[type="week"]:focus, form.dark input[type="email"]:focus, form.dark input[type="number"]:focus, form.dark input[type="search"]:focus, form.dark input[type="tel"]:focus, form.dark input[type="time"]:focus, form.dark input[type="url"]:focus, form.dark input[type="color"]:focus, form.dark textarea:focus {
  background: none;
  border: 1px solid #999;
}

p#thanks {
  color: #ccc;
  padding-bottom: 10px;
}

label.error {
  color: #ff5242;
  padding-bottom: 10px;
  position: relative;
  top: -20px;
}

form textarea {
  height: 200px;
}

input.button:hover {
  color: #eee;
}

p#thanks {
  color: #ccc;
  padding: 40px 0;
}

/* case study */

.back-to-all {
  position: absolute;
  right: 40px;
  top: 30px;
  z-index: 2;
}

.case-header {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding: 80px 20px 20px;
}

.media-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.case-header video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.case-header .case-header-image {
  width: 100%;
  height: 100%;
  background: url(../../images/@stock/work-7.jpg) center center no-repeat;
  background-size: cover;
}

.case-header .image-overlay {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
}

.case-header .action-hint {
  position: absolute;
  bottom: 50px;
  right: 10%;
  color: #fff;
}

.case-header .action-hint i {
  color: #fff;
}

.case-header .case-title {
  position: absolute;
  top: 40%;
  left: 10%;
  padding-right: 10%;
  color: #fff;
}

.case-header .case-title h2 {
  color: #fff;
  font-size: 60px;
  font-family: montserrat;
  margin-bottom: 40px;
}

.case-header .case-title p {
  font-size: 24px;
  margin-bottom: 40px;
  line-height: 1.3em;
}

.case-study-content {
  position: relative;
  top: 100vh;
}
.case-study-content h3.meta-title {
  font-size: 16px;
  margin-bottom: 5px;
  position: relative;
}

.case-study-content h3.meta-title::before {
  content: "";
  width: 10px;
  height: 1px;
  display: block;
  background: #ff5242;
  position: absolute;
  left: -20px;
  top: 8px;
}

.case-study-content .launch i {
  font-size: 20px;
  position: relative;
  top: 5px;
}

.case-video-wrapper {
  position: relative;
  width: 100%;
  height: 700px;
  // max-height: 800px;
}

// .case-video-wrapper .case-image-placeholder {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   min-height: 600px;
// }

.case-video-wrapper video {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  object-fit: cover;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  filter: contrast(110%) brightness(103%);
}

.case-nav .columns {
  padding: 0;
}

.item.small-nav {
  border: none;
  width: 100%;
  height: 300px;
  padding: 50px 50px 0;
}

.item.small-nav a {
  padding-top: 0;
}

.item.small-nav  .circle-wrap {
  width: 200px;
  height: 200px;
}

.item.small-nav::before {
  display: none;
}

.item.small-nav .nav-title span {
  display: block;
}

.item.small-nav .nav-title span.sub-title {
  color: #999;
  font-family: montserrat;
  text-transform: uppercase;
}

.item.small-nav .nav-title span.title {
  font-size: 18px;
  color: #000;
}

.item.small-nav.previous .nav-title {
  position: absolute;
  top: 130px;
  left: 270px;
}

.item.small-nav.next  .circle-wrap {
  position: absolute;
  right: 50px;
}

.item.small-nav.next .nav-title {
  position: absolute;
  top: 130px;
  right: 270px;
}

.item.small-nav.next .nav-title span {
  text-align: right;
}







